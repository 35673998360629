import { styled } from "lib/theme";

export const BannerGroup = styled.div`
  &:not(:empty) {
    margin-top: 20px;
  }

  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
`;
