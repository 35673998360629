import { z } from "zod";

// Any changes here should be reflected in vercel-edge-config-schema.json
export const BannerSchema = z.object({
  message: z.string(),
  type: z.enum(["info", "warning", "error", "success"]).default("info"),
  link: z
    .object({
      href: z.string(),
      text: z.string(),
    })
    .optional(),
});

export type Banner = z.infer<typeof BannerSchema>;
