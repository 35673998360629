import { config } from "@/config";
import { getEditTableUrl, getEditZapUrl } from "@/lib/route-helpers";
import { styled } from "@/lib/theme";
import {
  InterfaceLinkedAsset,
  LinkedAssetType,
} from "@/server/api/projects/types";
import {
  Alert,
  AlertDescription,
  Icon,
  IconName,
  Link,
  ServiceIconShell,
  Text,
} from "@zapier/design-system";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export function LinkedAssets({ assets }: { assets: InterfaceLinkedAsset[] }) {
  if (assets.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Alert status="warning">
        <AlertDescription>
          Linked assets won&apos;t change ownership, which may cause automations
          to break if they aren&apos;t shared.
        </AlertDescription>
      </Alert>
      <div>
        <Text color="TextWeaker" margin={`0 0 8 0`} tag="p">
          The following linked assets will not change ownership:
        </Text>
        <AssetList assets={assets} />
      </div>
    </Wrapper>
  );
}

const AssetListWrapper = styled.ul`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

function AssetList({ assets }: { assets: InterfaceLinkedAsset[] }) {
  return (
    <AssetListWrapper>
      {assets.map((asset) => (
        <LinkedAssetItem asset={asset} key={asset.asset_id} />
      ))}
    </AssetListWrapper>
  );
}

const LinkedAssetItemWrapper = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const linkedAssetConfig: Record<
  LinkedAssetType,
  {
    icon: IconName;
    getName: (asset: InterfaceLinkedAsset) => string;
    getLink: (asset: InterfaceLinkedAsset) => string;
  }
> = {
  table: {
    icon: "navTableFill",
    getName: (asset: InterfaceLinkedAsset) => asset.name,
    getLink: (asset: InterfaceLinkedAsset) =>
      getEditTableUrl({ tableId: asset.asset_id }),
  },
  chatbot: {
    icon: "navAIChatbot",
    getName: (asset: InterfaceLinkedAsset) => asset.name,
    getLink: (asset: InterfaceLinkedAsset) =>
      `${config().NEXT_PUBLIC_ZAPIER_CHATBOTS_URL}/${asset.asset_id}`,
  },
  zap: {
    icon: "miscBoltAltFill",
    getName: (asset: InterfaceLinkedAsset) => asset.name,
    getLink: (asset: InterfaceLinkedAsset) =>
      getEditZapUrl({
        rootNodeId: asset.asset_id,
      }),
  },
};

function LinkedAssetItem({ asset }: { asset: InterfaceLinkedAsset }) {
  const assetConfig = linkedAssetConfig[asset.asset_type];

  return (
    <LinkedAssetItemWrapper>
      <ServiceIconShell size="small">
        <Icon color="BrandOrange" name={assetConfig.icon} size={12} />
      </ServiceIconShell>
      <Link
        target={"_blank"}
        href={assetConfig.getLink(asset)}
        rel="noreferrer"
      >
        <Text type="MinimalPrint2">{assetConfig.getName(asset)}</Text>
      </Link>
    </LinkedAssetItemWrapper>
  );
}
