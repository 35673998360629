import { useQuery } from "@tanstack/react-query";
import { Banner, BannerSchema } from "./schemas";

export function useBanner(): Banner | null {
  const { data } = useQuery<unknown>({
    queryKey: ["banner"],
    queryFn: async () => {
      const response = await fetch("/api/banner");
      return await response.json();
    },
    refetchOnWindowFocus: true,
    throwOnError: false,
  });

  // We're not going to be fancy with loading and error states here. We've
  // either got a banner or we don't.
  if (!data) {
    return null;
  }

  try {
    return BannerSchema.parse(data);
  } catch (error) {
    // Since this functionality isn't critical, we should fail gracefully
    // and log the error.
    console.error("Failed to parse banner", { error });
    return null;
  }
}
