import { Colors, Icon, Text } from "@zapier/design-system";
import { styled } from "lib/theme";

const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 150px;
`;

const DeleteMenuItemWrapper = styled(MenuItemWrapper)`
  color: ${Colors.StatusError};
`;

export function CloneMenuItem() {
  return (
    <MenuItemWrapper>
      <Icon name="actionCopy" size={20} />
      <Text type="Body3" color="inherit" hasTransition={false}>
        Clone
      </Text>
    </MenuItemWrapper>
  );
}

export function ZSLMenuItem() {
  return (
    <MenuItemWrapper>
      <Icon name="arrowCode" size={20} />
      <Text type="Body3" color="inherit" hasTransition={false}>
        Get ZSL
      </Text>
    </MenuItemWrapper>
  );
}

interface ProjectOverviewMenuItemProps {
  projectOverviewUrl: string;
}

export function ProjectOverviewMenuItem({
  projectOverviewUrl,
}: ProjectOverviewMenuItemProps) {
  return (
    <MenuItemWrapper
      onClick={() => (window.location.href = projectOverviewUrl)}
    >
      <Icon name="actionEdit" size={20} />
      <Text type="Body3" color="inherit" hasTransition={false}>
        Overview
      </Text>
    </MenuItemWrapper>
  );
}

export function DeleteMenuItem() {
  return (
    <DeleteMenuItemWrapper>
      <Icon name="actionTrash" size={22} />
      <Text type="Body3" color="inherit" hasTransition={false}>
        Delete
      </Text>
    </DeleteMenuItemWrapper>
  );
}

export function ChangeOwnerMenuItem() {
  return (
    <MenuItemWrapper>
      <Icon name="arrowConnect" size={20} />
      <Text type="Body3" color="inherit" hasTransition={false}>
        Change owner
      </Text>
    </MenuItemWrapper>
  );
}
