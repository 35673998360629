import { projectPath } from "@/lib/route-helpers";
import { styled } from "@/lib/theme";
import { zrpc } from "@/lib/zrpc";
import { useMutation } from "@tanstack/react-query";
import { TextInput, Text, Icon } from "@zapier/design-system";
import { useRouter } from "next/router";
import { useRef } from "react";
import { Button } from "@/components/Button";

const PromptFieldset = styled.fieldset`
  display: flex;
  gap: 12px;
  margin-top: 24px;
  align-items: center;
`;

const AIRecommendationBox = styled.section`
  padding: var(--zds-space-32);
  display: flex;
  flex-direction: column;
`;

const GradientBorder = styled.div`
  background: var(--zds-gradient-gradient-1);
  border-radius: 10px;
  padding: 1px;
  margin-bottom: var(--zds-space-24);
`;

const GradientBorderInner = styled.div`
  background-color: var(--zds-background-default);
  border-radius: 9px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--zds-space-4);
  background-color: transparent;
  color: var(--zds-text-weaker);
  border: var(--zds-border-default);
  padding: var(--zds-space-0) var(--zds-space-4);
  border-radius: var(--zds-radius-small);
  height: var(--zds-size-24);
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--zds-space-8);
  align-items: center;
`;

const ButtonWrapper = styled.div`
  flex: none;
`;

export function AIPrompt() {
  const { mutateAsync: executeTemplate } = zrpc.zslapi.useMutation(
    "post",
    "/api/zsl/v0/templates/execute"
  );

  const router = useRouter();
  const formRef = useRef<HTMLFormElement>(null);

  const { isPending, mutate: build } = useMutation({
    mutationFn: async (prompt: string) => {
      const plan = await fetchZSLPlan({ prompt });
      const template = await fetchZSLTemplate({ prompt: plan });
      const {
        data: { interfaces },
      } = await executeTemplate({ body: { template } });

      const projectId = Object.keys(interfaces)[0];
      if (!projectId) {
        throw new Error("The ZSL did not include any Interface.");
      }

      /**
       * This will NOT work locally or in sandbox.
       * The ZSL-API creates resources on staging or production. It does not create them on our local / sandbox database.
       */
      await router.push(projectPath({ projectId }));
    },
    onSuccess: () => {
      formRef.current?.reset();
    },
  });

  return (
    <GradientBorder>
      <GradientBorderInner>
        <AIRecommendationBox>
          <HeaderWrapper>
            <Text type="PageHeader" tag={"h2"}>
              Build your app faster
            </Text>
            <IconWrapper>
              <Icon name="miscEvergreenAI" size={14} />
              <Text type="MinimalPrint2" color="TextWeakest">
                AI
              </Text>
            </IconWrapper>
          </HeaderWrapper>
          <Text type="Body1" color="GrayWarm8">
            Tell us what you're thinking, and we'll get you started faster...
          </Text>
          <form
            ref={formRef}
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const prompt = formData.get("prompt") as string;
              build(prompt);
            }}
          >
            <PromptFieldset disabled={isPending}>
              <TextInput
                placeholder="Build me an app that..."
                name="prompt"
                id="prompt"
                isFullWidth
              />
              <ButtonWrapper>
                <Button
                  isLoading={isPending}
                  color="secondary"
                  size="medium"
                  type="submit"
                >
                  {isPending ? "Building..." : "Build"}
                </Button>
              </ButtonWrapper>
            </PromptFieldset>
          </form>
        </AIRecommendationBox>
      </GradientBorderInner>
    </GradientBorder>
  );
}

async function fetchZSLPlan({ prompt }: { prompt: string }) {
  const response = await fetch("/api/zsl/plan", {
    method: "POST",
    body: JSON.stringify({ prompt }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch ZSL outline");
  }

  const reader = response.body
    ?.pipeThrough(new TextDecoderStream())
    .pipeThrough(new PromptOutlineTransformer())
    .getReader();
  if (!reader) {
    throw new Error("Failed to get reader");
  }

  let rawOutline = "";
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }

    if (value) {
      rawOutline += value;
    }
  }

  const { plan } = JSON.parse(rawOutline);
  return plan;
}

async function fetchZSLTemplate({ prompt }: { prompt: string }) {
  const response = await fetch("/api/zsl/generate", {
    method: "POST",
    body: JSON.stringify({ prompt }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch ZSL template");
  }

  const reader = response.body
    ?.pipeThrough(new TextDecoderStream())
    .pipeThrough(new ZSLStreamFilter())
    .pipeThrough(new JSONParser())
    .getReader();

  if (!reader) {
    throw new Error("Failed to get reader");
  }

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }

    if (value) {
      return value;
    }
  }
}

class PromptOutlineTransformer extends TransformStream {
  constructor() {
    super({
      transform(chunk: string, controller) {
        const normalizedChunk = chunk
          .replaceAll("data: ", "")
          .replaceAll("\n", "");

        if (normalizedChunk.length > 0) {
          controller.enqueue(normalizedChunk);
        }
      },
    });
  }
}

class ZSLStreamFilter extends TransformStream {
  constructor() {
    super({
      transform(chunk: string, controller) {
        const isDecompressed = chunk
          .toString()
          .startsWith("event: decompressed");

        if (!isDecompressed) {
          return;
        }

        const rawValue = chunk
          .toString()
          .replaceAll("event: decompressed\ndata: ", "")
          .replaceAll("event: end\ndata: undefined", "")
          .replaceAll("\n", "")
          .trim();

        controller.enqueue(rawValue);
      },
    });
  }
}

class JSONParser extends TransformStream {
  constructor() {
    super({
      transform(chunk: string, controller) {
        const value = JSON.parse(chunk);
        controller.enqueue(value);
      },
    });
  }
}
