import { Alert, Link } from "@zapier/design-system";

export function UpgradeBanner({
  canCreateProjects,
  isUpgraded,
}: {
  canCreateProjects: boolean;
  isUpgraded: boolean;
}) {
  if (canCreateProjects && isUpgraded) return null;

  const projectCountAlert = !canCreateProjects
    ? "You've reached the limit for Interfaces created on your current plan. "
    : "";
  const message = `Upgrade to unlock ${
    !canCreateProjects ? "more Interfaces, " : ""
  } custom branding & domains, restricted access, and more`;

  return (
    <Alert variant="alert">
      {projectCountAlert}
      {message}. <Link href="/pricing">Learn more.</Link>
    </Alert>
  );
}
