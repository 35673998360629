import {
  ModalContent,
  ModalContentBody,
  ModalContentFooter,
  ModalContentHeader,
  Text,
  Grid,
} from "@zapier/design-system";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { styled } from "lib/theme";
import { LinkedAssets } from "./LinkedAssets";
import { getDisplayText, SelectOwner } from "./SelectOwner";
import { CollaboratorWithInterfacesAccount } from "@/server/api/routes/collaborators/v0_search_collaborators.";
import { Suspense, useState } from "react";
import { Spinner } from "../Spinner";
import { zrpc } from "@/lib/zrpc";
import { toast } from "@/components/Toaster";
import { useQueryClient } from "@tanstack/react-query";
import { deleteInterfaceFromListListingPageQueryData } from "@/lib/query-cache-helpers/deleteProjectFromListListingPageQueryData";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

type TransferOwnerModalProps = {
  projectId: string;
  projectName: string;
  newOwner?: CollaboratorWithInterfacesAccount;
  onClosed: () => void;
};

export function TransferOwnerModal({
  projectId,
  projectName,
  newOwner,
  onClosed,
}: TransferOwnerModalProps) {
  return (
    <Modal onClosed={onClosed} aria-label="Transfer Interface Ownership">
      <ModalContent maxWidth="60ch">
        <Suspense fallback={<Spinner height={200} />}>
          <TransferOwnerModalInner
            newOwner={newOwner}
            projectId={projectId}
            projectName={projectName}
            closeModal={onClosed}
          />
        </Suspense>
      </ModalContent>
    </Modal>
  );
}

function TransferOwnerModalInner({
  projectId,
  projectName,
  newOwner,
  closeModal,
}: {
  projectId: string;
  projectName: string;
  newOwner?: CollaboratorWithInterfacesAccount;
  closeModal: () => void;
}) {
  const queryClient = useQueryClient();

  const [selectedMember, setSelectedMember] =
    useState<CollaboratorWithInterfacesAccount | null>(null);

  const transferButtonEnabled = Boolean(newOwner ?? selectedMember);

  const { data: accountMembers = [] } = zrpc.interfaces.useSuspenseQuery(
    "get",
    "/api/interfaces/v0/interfaces/{interfaceId}/collaborators/search",
    {
      params: {
        path: { interfaceId: projectId },
        query: { search: "" },
      },
    }
  );

  const { data: linkedAssetsResult } = zrpc.interfaces.useSuspenseQuery(
    "get",
    "/api/interfaces/v0/interfaces/{interfaceId}/linked-assets",
    {
      params: {
        path: {
          interfaceId: projectId,
        },
      },
    }
  );

  const linkedAssets = linkedAssetsResult?.data ?? [];

  const transferOwnership = zrpc.interfaces.useMutation(
    "post",
    "/api/interfaces/v0/interfaces/{interfaceId}/ownership/transfer"
  );

  const handleTransferOwnership = async () => {
    if (!newOwner && !selectedMember) return;

    if (newOwner && selectedMember) {
      // This should not happen since we do not show accout member selection if newOwner is defined
      return;
    }

    const newOwnerZapierId = newOwner
      ? newOwner.id
      : selectedMember
        ? selectedMember.id
        : undefined;

    if (!newOwnerZapierId) {
      return;
    }

    await transferOwnership.mutateAsync({
      params: {
        path: {
          interfaceId: projectId,
        },
      },
      body: {
        new_owner_zapier_id: newOwnerZapierId,
      },
    });

    deleteInterfaceFromListListingPageQueryData(queryClient, [projectId]);

    toast.success({
      message: "Ownership transferred successfully",
    });

    closeModal();
  };

  return (
    <>
      <ModalContentHeader>
        <Text type="ParagraphHeader3">Transfer {projectName} ownership</Text>
      </ModalContentHeader>
      <ModalContentBody>
        <Grid columns={1} gap={16}>
          {newOwner ? (
            <Text tag="p" type="Body1" color="GrayWarm9">
              You are about to transfer ownership of this Interface to{" "}
              {getDisplayText(newOwner)}. Once transferred, it can't be
              reversed.
            </Text>
          ) : (
            <Text tag="p" type="Body1" color="GrayWarm9">
              Select a member from your organization to be the new owner of this
              Interface. Once transferred, it can't be reversed.
            </Text>
          )}
          <LinkedAssets assets={linkedAssets} />
          {!newOwner && (
            <SelectOwner
              selectedMember={selectedMember}
              onSelectMember={(member) => {
                setSelectedMember(member);
              }}
              initialData={accountMembers}
              projectId={projectId}
            />
          )}
        </Grid>
      </ModalContentBody>
      <ModalContentFooter>
        <ButtonWrapper>
          <Button type="button" color="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => handleTransferOwnership()}
            disabled={!transferButtonEnabled}
            isLoading={transferOwnership.isPending}
          >
            Change owner
          </Button>
        </ButtonWrapper>
      </ModalContentFooter>
    </>
  );
}
