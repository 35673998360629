import { Alert, Link } from "@zapier/design-system";
import { Banner } from "@/app/api/banner/(model)/schemas";

export function EdgeConfigBanner({ banner }: { banner: Banner | null }) {
  if (!banner) return null;

  return (
    <Alert variant="alert" status={banner.type}>
      {banner.message}
      {banner.link != undefined && (
        <>
          {" "}
          <Link href={banner.link.href}>{banner.link.text}</Link>
        </>
      )}
    </Alert>
  );
}
