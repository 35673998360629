import { zrpc } from "@/lib/zrpc";
import { CollaboratorWithInterfacesAccount } from "@/server/api/routes/collaborators/v0_search_collaborators.";
import { Field, Colors, Avatar } from "@zapier/design-system";
import { Typeahead, Key } from "@zapier/design-system-beta";
import Tokens from "@zapier/design-tokens";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { styled } from "lib/theme";
import { css } from "styled-components";

const disabledDropdownItemCss = css`
  color: ${Colors.TextWeakest};
  background-color: ${Colors.BackgroundStrongest};
  img,
  svg {
    filter: grayscale(100%);
  }
`;

type Props = {
  onSelectMember: (
    collaborator: CollaboratorWithInterfacesAccount | null
  ) => void;
  selectedMember: CollaboratorWithInterfacesAccount | null;
  initialData: CollaboratorWithInterfacesAccount[];
  projectId: string;
};

export function SelectOwner({
  onSelectMember,
  selectedMember,
  initialData,
  projectId,
}: Props) {
  const [fieldState, setFieldState] = useState<{
    inputValue: string;
    searchValue: string;
  }>({
    inputValue: "",
    searchValue: "",
  });

  const [debouncedSearchValue] = useDebounce(fieldState.searchValue, 500);

  const { data: accountMembers = [], isFetching } = zrpc.interfaces.useQuery(
    "get",
    "/api/interfaces/v0/interfaces/{interfaceId}/collaborators/search",
    {
      params: {
        path: { interfaceId: projectId },
        query: { search: debouncedSearchValue },
      },
    },
    {
      initialData,
    }
  );

  const isLoading =
    isFetching || debouncedSearchValue !== fieldState.searchValue;

  const typeaheadOptions = accountMembers.map((member) => ({
    ...member,
    disabled: !member.hasInterfacesAccount,
  }));

  const onSelectionChange = (memberId: Key | null) => {
    // When user clears the selection
    if (memberId === null) {
      onSelectMember(null);
      setFieldState({
        inputValue: "",
        searchValue: "",
      });

      return;
    }
    const isSelectedMemberSame = selectedMember?.id === memberId;
    if (isSelectedMemberSame) {
      return;
    }

    const newSelectedMember = accountMembers?.find(
      (member) => member.id === memberId
    );

    if (!newSelectedMember) {
      return;
    }

    onSelectMember(newSelectedMember);
    setFieldState((prevState) => ({
      inputValue: getDisplayText(newSelectedMember),
      searchValue: prevState.searchValue,
    }));
  };

  const onInputChange = (value: string) => {
    setFieldState({
      inputValue: value,
      searchValue: value,
    });
  };

  return (
    <AccountMemberTypeAhead
      isLoading={isLoading}
      typeaheadOptions={typeaheadOptions}
      onSelectionChange={onSelectionChange}
      inputValue={fieldState.inputValue}
      setInputValue={onInputChange}
      selectedKey={selectedMember?.id ?? null}
    />
  );
}

function AccountMemberTypeAhead({
  isLoading,
  typeaheadOptions,
  onSelectionChange,
  inputValue,
  setInputValue,
  selectedKey,
}: {
  isLoading: boolean;
  typeaheadOptions: CollaboratorWithInterfacesAccount[];
  onSelectionChange: (key: Key | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  selectedKey: Key | null;
}) {
  return (
    <>
      <Field
        label="New owner"
        isRequired={true}
        requiredText={null}
        renderInput={(inputProps) => {
          return (
            <Typeahead
              {...inputProps}
              aria-label={"List of available members"}
              isBusy={isLoading}
              menuTrigger="focus"
              placeholder="Search a member"
              items={typeaheadOptions}
              onSelectionChange={onSelectionChange}
              inputValue={inputValue}
              onInputChange={setInputValue}
              selectedKey={selectedKey}
              size="medium"
            >
              {(item) => {
                // We need to cast this to Collaborator because Typeahead doesn't accept a generic type parameter to
                // specify the type of the items.
                const member =
                  item as unknown as CollaboratorWithInterfacesAccount & {
                    hasInterfacesAccount: boolean;
                  };
                const isDisabled = !member.hasInterfacesAccount;

                return (
                  <TypeaheadItem isDisabled={isDisabled} member={member} />
                );
              }}
            </Typeahead>
          );
        }}
      />
    </>
  );
}

const ItemWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  gap: ${Tokens.Space8};
  padding: ${Tokens.Space2};

  ${({ isDisabled }) =>
    isDisabled &&
    `background: ${Colors.BackgroundStrongest}; color: ${Colors.TextWeakest}`}

  img,svg {
    ${({ isDisabled }) => isDisabled && `filter: grayscale(100%);`}
  }
`;

function TypeaheadItem({
  member,
  isDisabled,
}: {
  member: CollaboratorWithInterfacesAccount;
  isDisabled: boolean;
}) {
  return (
    <Typeahead.Item
      id={member.id}
      key={member.id}
      textValue={getDisplayText(member)}
      // @ts-expect-error TypeaheadItem is internally ListBoxItem from react-aria-components which supports this prop
      isDisabled={isDisabled}
      css={isDisabled ? disabledDropdownItemCss : undefined}
    >
      <ItemWrapper isDisabled={isDisabled}>
        <Avatar
          name={member.name}
          size="medium"
          url={member.avatarUrl}
          isBlock
        />
        {getDisplayText(member)}
      </ItemWrapper>
    </Typeahead.Item>
  );
}

export function getDisplayText(member: CollaboratorWithInterfacesAccount) {
  return `${member.name} (${member.email})`;
}
