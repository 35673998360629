import { useState } from "react";

import { Spacer, Text } from "@zapier/design-system";
import styled from "styled-components";

import { useSplitFlags } from "@/lib/context/split-context";
import { handleTemplateError } from "@/lib/utils/handleTemplateError";
import { zrpc } from "@/lib/zrpc";
import { type ZslTemplate } from "@/server/services/zslapi";
import { LoadingModal } from "components/LoadingModal";
import { TemplateGrid } from "components/TemplateGrid";
import TemplateHandler from "components/TemplateHandler";
import { projectPath } from "lib/route-helpers";
import { useRouter } from "next/router";
import Header from "./Header";
import { SimpleCard } from "./SimpleCard";
import formTemplate from "./SimpleTemplates/form";
import kanbanTemplate from "./SimpleTemplates/kanban";
import tableTemplate from "./SimpleTemplates/table";
import FormImage from "./form.png";
import KanbanImage from "./kanban.png";
import TableImage from "./table.png";
import { AIPrompt } from "@/components/Projects/AIPrompt";

const SimpleCards = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin: 25px 0 60px;
  grid-gap: 20px;
`;

interface ZeroStateProps {
  className?: string;
  onClick: () => void;
}

export function ZeroState({ onClick }: ZeroStateProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  const { mutateAsync: executeTemplate } = zrpc.zslapi.useMutation(
    "post",
    "/api/zsl/v0/templates/execute",
    {
      meta: {
        noToast: true,
      },
    }
  );

  const createFromTemplate = async (zsl: ZslTemplate) => {
    setLoading(true);

    try {
      const response = await executeTemplate({
        body: { template: zsl, input: {} },
      });

      const projectId = Object.keys(response.data?.interfaces)[0];

      if (projectId) {
        const path = projectPath({ projectId: projectId });
        return router.push(path);
      }
    } catch (err: any) {
      handleTemplateError(err, zsl);
    } finally {
      setLoading(false);
    }
  };

  const [aiCreationEnabled] = useSplitFlags(["interfaces_ai_creation"]);

  return (
    <section data-testid="projects-zero-state">
      <Header startBlank={onClick} />
      <Spacer height={20} />
      {aiCreationEnabled ? (
        <AIPrompt />
      ) : (
        <RegularZeroState onCreateFromTemplate={createFromTemplate} />
      )}
      <Text type="ParagraphHeader2">Templates</Text>
      <TemplateGrid handleCreate={createFromTemplate} />
      <TemplateHandler />
      {loading ? <LoadingModal /> : null}
    </section>
  );
}

function RegularZeroState({
  onCreateFromTemplate,
}: {
  onCreateFromTemplate: (zsl: ZslTemplate) => void;
}) {
  return (
    <>
      <Text type="ParagraphHeader2">Start simple</Text>
      <SimpleCards title="Simple templates">
        <li className="w-full">
          <SimpleCard
            title="Form"
            image={FormImage}
            onClick={() => onCreateFromTemplate(formTemplate)}
            triggers={["Form is submitted"]}
          />
        </li>
        <li className="w-full">
          <SimpleCard
            title="Table"
            image={TableImage}
            onClick={() => onCreateFromTemplate(tableTemplate)}
            triggers={[
              "New record is added",
              "Record is updated",
              "Zap button is clicked",
            ]}
          />
        </li>
        <li className="w-full">
          <SimpleCard
            title="Kanban"
            image={KanbanImage}
            onClick={() => onCreateFromTemplate(kanbanTemplate)}
            triggers={[
              "Card changes columns",
              "New card is created",
              "Card details are updated",
            ]}
          />
        </li>
      </SimpleCards>
    </>
  );
}
